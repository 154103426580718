import React, { useEffect, useState } from 'react'
import { Spinner } from 'flowbite-react'
import Screen from '../components/Screen'
import { sendMessage } from '../lib/utils';
import checkOcrRequest from '../lib/checkOcrRequest';

function Result({ data, accessToken, contractId, images, setError }) {
  const [isCompleted, setIsCompleted] = useState(false)

  useEffect(() => {
    if (data && images) {
      sendMessage("frontImages", { data: { frontImage: images.frontImage, faceImage: images.faceImage }, message: "OK" });
      sendMessage("backImages", { data: { backImage: images.backImage }, message: "OK" });
      let args = {
        token: accessToken,
        contractId: contractId,
        ocr: data
      };
      checkOcrRequest(args).then(result => {
        if (!result) {
          sendMessage("userFinishedSdk", { data, message: "FAILURE" });
          setError({ error: true, code: 1, reason: 'OCR Check failure' })    
        } else {
          sendMessage("userFinishedSdk", { data, message: "OK" });
        }
      })
      .catch(error => {
        setError({ error: true, code: 1, reason: error.message })
      })
      
      setIsCompleted(true)
    }
  }, [data, accessToken, contractId, images, setError])

  return (
    <Screen
      title='Resultado'
      main={
        <div className="text-center">
          {!isCompleted && <Spinner aria-label="Loading spinner" size="xl" />}
          <p className='text-md pt-2'>
            {isCompleted ? "¡Completado!" : "Espere por favor. Estamos analizando y generando resultados..."}
          </p>
        </div>
      }
    />
  )
}

export default Result
