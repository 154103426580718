const checkOcrRequest = async (args) => {
    let response = await fetch(`${process.env.REACT_APP_BE_API_URL}/OCRCheck`, {
        method: 'POST',
        headers: new Headers({
            "Authorization": `Bearer ${args.token}`,
            'Content-Type': 'application/json; charset=UTF-8'
        }),
        body: JSON.stringify({
            contract_id: args.contractId,
            ocr: args.ocr
        })
    });
    if(response.ok) {
        return response.json()
    }
    return false
};

export default checkOcrRequest;