import React from 'react'
import Screen from '../components/Screen';
import DocumentScanner from "../components/DocumentScanner"

function ScanDocument({ setData, setImages }) {
  return (
    <>
      <Screen
        title="Escanear documento"
        main={<DocumentScanner setData={setData} setImages={setImages} />} />
    </>
  )
}

export default ScanDocument
